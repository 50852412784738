.header-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0e0e0e;
}
.header-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-image {
  width: 201px;
  height: 66px;
  object-fit: cover;
}
.header-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.header-text {
  color: var(--dl-color-primary-primary);
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.header-text:hover {
  color: var(--dl-color-turquoise-default);
}
.header-text1 {
  color: var(--dl-color-primary-primary);
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.header-text1:hover {
  color: var(--dl-color-turquoise-default);
}
.header-text2 {
  color: var(--dl-color-primary-primary);
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.header-text2:hover {
  color: var(--dl-color-turquoise-default);
}
.header-text3 {
  color: var(--dl-color-primary-primary);
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.header-text3:hover {
  color: var(--dl-color-turquoise-default);
}
.header-text4 {
  color: var(--dl-color-primary-primary);
  transition: 0.3s;
}
.header-text4:hover {
  color: var(--dl-color-turquoise-default);
}
.header-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-menu1 {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .header-container {
    display: none;
  }
}
@media(max-width: 767px) {
  .header-menu {
    display: none;
  }
  .header-burger-menu {
    display: block;
  }
  .header-menu1 {
    fill: var(--dl-color-primary-primary);
  }
}
@media(max-width: 479px) {
  .header-container {
    display: none;
  }
  .header-menu1 {
    fill: var(--dl-color-primary-primary);
  }
}
