.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #0e0e0e;
}
.section  {
  margin: 50px 0px 50px 0px;
  /* height: 50vh; */
}
.home-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #0e0e0e;
}
.spacemenu {
  padding-bottom: 20px;
}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #0e0e0e;
 
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 80%;
  object-fit: cover;

}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon {
  width: 24px;
  height: 24px;
  background-color: white;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  margin-top: 50px;
}
.home-text {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
  margin-top: 20px;
}
.home-text:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text01 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text01:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text02 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text02:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text03 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text03:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text04 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text04:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text05 {
  transition: 0.3s;
}
.home-text05:hover {
  color: var(--dl-color-turquoise-default);
}
.home-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-container01 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-container02 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon02 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon02:hover {
  fill: var(--dl-color-purple-default);
}
.home-link1 {
  display: contents;
}
.home-container03 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon04 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon04:hover {
  fill: var(--dl-color-pink-default);
}
.home-link2 {
  display: contents;
}
.home-container04 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon06 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon06:hover {
  fill: var(--dl-color-turquoise-default);
}
.home-link3 {
  display: contents;
}
.home-container05 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon08 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon08:hover {
  fill: var(--dl-color-orange-default);
}
.home-slider {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-background-back);
}
.home-hero {
  width: 100%;
  height: 849px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container06 {
  flex: 0 0 auto;
  width: auto;
  height: 198px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text06 {
  color: var(--dl-color-primary-primary);
  width: 642px;
  height: 292px;
  font-size: 40px;
  margin-bottom: var(--dl-space-space-doubleunit);
  margin-bottom: 100px;
}
.home-image01 {
  width: 320px;
  object-fit: cover;
}
.home-turquoise-cirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-left {
  top: 653px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-right {
  top: 441px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-aliados {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-image02 {
  width: 100px;
  object-fit: cover;
}
.home-image03 {
  width: 168px;
  height: 77px;
  object-fit: cover;
}
.home-image04 {
  width: 100px;
  object-fit: cover;
}
.home-image05 {
  width: 100px;
  object-fit: cover;
}
.home-image06 {
  width: 100px;
  object-fit: cover;
}
.home-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-somos {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 540px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.home-text07 {
  color: var(--dl-color-primary-primary);
  font-size: 30px;
}
.home-image07 {
  width: 360px;
  margin-top: 100px;
  object-fit: cover;
}
.home-container08 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text08 {
  color: var(--dl-color-primary-primary);
  font-size: 18px;
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  font-family: Inter;
  font-weight: 400;
  line-height: 1.77;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.home-turquoise-cirble1 {
  top: 1400px;
  right: 89px;
  width: 170px;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle1 {
  top: 1070px;
  left: 0px;
  width: 400px;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-rewards {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text09 {
  color: var(--dl-color-primary-primary);
  font-size: 30px;
}
.home-container10 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text10 {
  color: var(--dl-color-primary-primary);
  font-size: 18px;
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  font-family: Inter;
  font-weight: 400;
  line-height: 1.77;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.home-container11 {
  flex: 0 0 auto;
  width: 540px;
  height: 540px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.home-image08 {
  width: 340px;
  height: 750px;
  margin-top: 100px;
  object-fit: cover;
}
.home-turquoise-cirble2 {
  top: 1733px;
  left: 130px;
  width: 170px;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-cash {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container12 {
  flex: 0 0 auto;
  width: 540px;
  height: 540px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.home-text11 {
  color: var(--dl-color-primary-primary);
  font-size: 30px;
}
.home-image09 {
  width: 360px;
  margin-top: 100px;
  object-fit: cover;
}
.home-container13 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text12 {
  color: var(--dl-color-primary-primary);
  font-size: 18px;
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  font-family: Inter;
  font-weight: 400;
  line-height: 1.77;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.home-turquoise-cirble3 {
  top: 2279px;
  left: 32px;
  width: 170px;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle2 {
  top: 2541px;
  right: 87px;
  width: 400px;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-cryptos {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container14 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text13 {
  color: var(--dl-color-primary-primary);
  font-size: 30px;
}
.home-container15 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text14 {
  color: var(--dl-color-primary-primary);
  font-size: 18px;
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  font-family: Inter;
  font-weight: 400;
  line-height: 1.77;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
}
.home-container16 {
  flex: 0 0 auto;
  width: 540px;
  height: 540px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.home-image10 {
  width: 360px;
  margin-top: 100px;
  object-fit: cover;
}
.home-turquoise-cirble4 {
  top: 2789px;
  left: 310px;
  width: 170px;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle3 {
  left: -74px;
  width: 400px;
  bottom: 105px;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 991px) {
  .home-hero {
    height: auto;
    flex-direction: column;
  }
  .home-container06 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-text06 {
    text-align: center;
  }
  .home-somos {
    flex-direction: column;
  }
  .home-container08 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-rewards {
    flex-direction: column;
  }
  .home-container10 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-cash {
    flex-direction: column;
  }
  .home-container13 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-cryptos {
    flex-direction: column;
  }
  .home-container15 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-purple-circle3 {
    left: -6px;
    bottom: 325px;
  }
}
@media(max-width: 767px) {
  .home-text06 {
    font-size: 50px;
    text-align: center;
  }
  .home-aliados {
    justify-content: center;
  }
  .home-image02 {
    margin: var(--dl-space-space-unit);
  }
  .home-image03 {
    margin: var(--dl-space-space-unit);
  }
  .home-image04 {
    margin: var(--dl-space-space-unit);
  }
  .home-image05 {
    margin: var(--dl-space-space-unit);
  }
  .home-image06 {
    margin: var(--dl-space-space-unit);
  }
  .home-turquoise-cirble4 {
    top: 3040px;
    right: 293px;
  }
}
@media(max-width: 479px) {
  .home-bot {
    width: 100%;
  }
  .home-text06 {
    width: 100%;
    height: auto;
  }
  .home-image01 {
    width: auto;
    height: 613px;
    margin-top: 250px;
  }
  .home-purple-circle {
    width: 188px;
    height: 123px;
  }
  .home-text08 {
    text-align: justify;
  }
  .home-turquoise-cirble1 {
    top: 1683px;
    right: 0px;
  }
  .home-purple-circle1 {
    top: 1842px;
    left: 0px;
  }
  .home-container10 {
    width: 100%;
  }
  .home-text10 {
    text-align: justify;
  }
  .home-container11 {
    width: 100%;
  }
  .home-turquoise-cirble2 {
    top: 2412px;
    left: 0px;
    width: 130px;
    height: 145px;
  }
  .home-container12 {
    width: 100%;
  }
  .home-container13 {
    width: 100%;
  }
  .home-text12 {
    text-align: justify;
  }
  .home-turquoise-cirble3 {
    top: 3281px;
    left: 0px;
  }
  .home-purple-circle2 {
    top: 3795px;
    right: 0px;
    width: 353px;
    height: 292px;
  }
  .home-text14 {
    text-align: justify;
  }
  .home-container16 {
    width: 100%;
  }
  .home-turquoise-cirble4 {
    top: 4294px;
    right: 8px;
    width: 189px;
    height: 152px;
  }
  .home-purple-circle3 {
    width: 282px;
    height: 266px;
  }
}


@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #0e0e0e;
  overflow: hidden;
}

.background span {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #b79944;
  top: 55%;
  left: 59%;
  animation-duration: 51s;
  animation-delay: -31s;
  transform-origin: -10vw -14vh;
  box-shadow: -40vmin 0 5.238506826525205vmin currentColor;
}
.background span:nth-child(1) {
  color: #c6bca0;
  top: 38%;
  left: 41%;
  animation-duration: 31s;
  animation-delay: -49s;
  transform-origin: 5vw -17vh;
  box-shadow: 40vmin 0 5.847077657268509vmin currentColor;
}
.background span:nth-child(2) {
  color: #cecece;
  top: 28%;
  left: 37%;
  animation-duration: 43s;
  animation-delay: -29s;
  transform-origin: -17vw -16vh;
  box-shadow: -40vmin 0 5.836092215332867vmin currentColor;
}
.background span:nth-child(3) {
  color: #beaa72;
  top: 75%;
  left: 57%;
  animation-duration: 47s;
  animation-delay: -26s;
  transform-origin: -4vw -17vh;
  box-shadow: -40vmin 0 5.07666902137794vmin currentColor;
}
.background span:nth-child(4) {
  color: #cecece;
  top: 20%;
  left: 4%;
  animation-duration: 53s;
  animation-delay: -9s;
  transform-origin: 4vw 15vh;
  box-shadow: -40vmin 0 5.693452111981349vmin currentColor;
}
.background span:nth-child(5) {
  color: #beaa72;
  top: 19%;
  left: 48%;
  animation-duration: 50s;
  animation-delay: -20s;
  transform-origin: -23vw -21vh;
  box-shadow: 40vmin 0 5.460499337631571vmin currentColor;
}
.background span:nth-child(6) {
  color: #E45A84;
  top: 25%;
  left: 88%;
  animation-duration: 15s;
  animation-delay: -27s;
  transform-origin: 12vw -22vh;
  box-shadow: -40vmin 0 5.545417531194142vmin currentColor;
}
.background span:nth-child(7) {
  color: #beaa72;
  top: 64%;
  left: 50%;
  animation-duration: 8s;
  animation-delay: -36s;
  transform-origin: 10vw -23vh;
  box-shadow: -40vmin 0 5.721654654915192vmin currentColor;
}
.background span:nth-child(8) {
  color: #E45A84;
  top: 91%;
  left: 93%;
  animation-duration: 28s;
  animation-delay: -17s;
  transform-origin: 18vw 6vh;
  box-shadow: 40vmin 0 5.570432524423739vmin currentColor;
}
.background span:nth-child(9) {
  color: #FFACAC;
  top: 34%;
  left: 55%;
  animation-duration: 55s;
  animation-delay: -41s;
  transform-origin: 25vw 20vh;
  box-shadow: -40vmin 0 5.200554988635366vmin currentColor;
}
.background span:nth-child(10) {
  color: #583C87;
  top: 88%;
  left: 87%;
  animation-duration: 24s;
  animation-delay: -20s;
  transform-origin: -12vw 16vh;
  box-shadow: -40vmin 0 5.8850551926528185vmin currentColor;
}
.background span:nth-child(11) {
  color: #FFACAC;
  top: 88%;
  left: 33%;
  animation-duration: 53s;
  animation-delay: -40s;
  transform-origin: 25vw -24vh;
  box-shadow: 40vmin 0 5.865596789149636vmin currentColor;
}
.background span:nth-child(12) {
  color: #E45A84;
  top: 66%;
  left: 83%;
  animation-duration: 31s;
  animation-delay: -27s;
  transform-origin: 6vw -11vh;
  box-shadow: -40vmin 0 5.5997577019724005vmin currentColor;
}
.background span:nth-child(13) {
  color: #E45A84;
  top: 23%;
  left: 8%;
  animation-duration: 53s;
  animation-delay: -3s;
  transform-origin: -8vw -24vh;
  box-shadow: 40vmin 0 5.62074153973273vmin currentColor;
}
.background span:nth-child(14) {
  color: #E45A84;
  top: 42%;
  left: 43%;
  animation-duration: 27s;
  animation-delay: -6s;
  transform-origin: -4vw 6vh;
  box-shadow: -40vmin 0 5.421843721287884vmin currentColor;
}
.background span:nth-child(15) {
  color: #583C87;
  top: 21%;
  left: 15%;
  animation-duration: 39s;
  animation-delay: -28s;
  transform-origin: -23vw -14vh;
  box-shadow: -40vmin 0 5.47733405712155vmin currentColor;
}
.background span:nth-child(16) {
  color: #583C87;
  top: 8%;
  left: 85%;
  animation-duration: 47s;
  animation-delay: -49s;
  transform-origin: 4vw -23vh;
  box-shadow: -40vmin 0 5.7688140115307105vmin currentColor;
}
.background span:nth-child(17) {
  color: #FFACAC;
  top: 24%;
  left: 4%;
  animation-duration: 44s;
  animation-delay: -47s;
  transform-origin: -1vw -10vh;
  box-shadow: -40vmin 0 5.46523180887646vmin currentColor;
}
.background span:nth-child(18) {
  color: #583C87;
  top: 62%;
  left: 76%;
  animation-duration: 16s;
  animation-delay: -6s;
  transform-origin: 9vw 7vh;
  box-shadow: 40vmin 0 5.150782129123174vmin currentColor;
}
.background span:nth-child(19) {
  color: #000000;
  top: 68%;
  left: 72%;
  animation-duration: 15s;
  animation-delay: -18s;
  transform-origin: -3vw -10vh;
  box-shadow: -40vmin 0 5.560811002665677vmin currentColor;
}

